import React from 'react'
import Img from 'gatsby-image'

import { Link } from 'gatsby'

import styles from './thumbs.module.scss'

export default class Thumbs extends React.PureComponent {
  render() {
    return (
      <div className={styles.thumbs}>
        {this.props.galleries.map((gallery, index) => {
          return (
            <div
              className={styles.thumb}
              key={`${index}${gallery.poster.fixed.src}`}
            >
              <Link title={gallery.title} to={gallery.slug}>
                <Img
                  alt={gallery.title}
                  placeholderStyle={{
                    opacity: 1,
                  }}
                  fadeIn={false}
                  style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: gallery.poster.colors.light,
                    paddingBottom: `${100 / gallery.poster.fixed.aspectRatio}%`,
                  }}
                  fixed={gallery.poster.fixed}
                />
                <label>
                  {gallery.title}
                  {gallery.formattedDate ? (
                    <small>{gallery.formattedDate}</small>
                  ) : null}
                </label>
              </Link>
            </div>
          )
        })}
      </div>
    )
  }
}
