import React from 'react'
import { sortBy } from 'lodash'
import { PhotoSwipeGallery } from 'react-photoswipe'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Thumbs from '../components/thumbs'

import Layout from '../components/layout'
import styles from './gallery.module.scss'

import 'react-photoswipe/lib/photoswipe.css'

export default class Project extends React.PureComponent {
  render() {
    const gallery = this.props.data.gallery.edges[0].node
    const { title, formattedDate, images, poster, slug, project } = gallery
    const { next, previous } = this.props.pathContext.links
    const galleries = sortBy(project.galleries, 'name')
      .reverse()
      .filter(gallery => gallery.slug !== slug)

    const photoSwipeOptions = {
      fullscreenEl: false,
      shareEl: false,
      history: false,
      getThumbBoundsFn: index => this.getThumbBoundsFn(index),
    }
    const photoSwipeData = this.getData(images)

    const previousCaption = gallery.formattedDate ? 'Newer' : 'Previous'
    const nextCaption = gallery.formattedDate ? 'Older' : 'Next'

    const titleSeparator =
      formattedDate && title.length - formattedDate.length > 2 ? '\n' : ' '
    const displayTitle = `${title}${
      formattedDate ? `,${titleSeparator}${formattedDate}` : ''
    }`

    return (
      <Layout
        title={`${project.title} - ${title}${
          formattedDate ? `, ${formattedDate}` : ''
        }`}
        currentProject={project}
        location={this.props.location}
        description={`Some photos from the ${project.title.toLowerCase()} ${title}.`}
        image={poster}
      >
        <div className={styles.project}>
          <h1>{displayTitle}</h1>
          <PhotoSwipeGallery
            onClose={() => {
              window.history.replaceState(null, null, slug)
            }}
            afterChange={ps => {
              window.history.replaceState(null, null, ps.currItem.slug)
              setTimeout(() => {
                this.scrollToIndex(ps.getCurrentIndex())
              }, 500)
            }}
            items={photoSwipeData}
            options={photoSwipeOptions}
            thumbnailContent={this.getThumbnailContent}
          />
          {(next || previous) && (
            <nav className={styles.simpleNav}>
              <div className={styles.newer}>
                {previous ? (
                  <Link to={previous.slug} title={previous.title}>
                    {previousCaption}
                  </Link>
                ) : (
                  <span className={styles.disabled}>{previousCaption}</span>
                )}
              </div>
              <div className={styles.older}>
                {next ? (
                  <Link to={next.slug} title={next.title}>
                    {nextCaption}
                  </Link>
                ) : (
                  <span className={styles.disabled}>{nextCaption}</span>
                )}
              </div>
            </nav>
          )}
        </div>
        {galleries.length > 10 ? (
          <nav className={styles.galleries}>
            <h2 className={styles.header}>More stuff</h2>
            <Thumbs galleries={galleries} />
          </nav>
        ) : null}
      </Layout>
    )
  }

  getData(images) {
    return images.reduce((memo, image) => {
      memo.push({
        ...image,
        ...image.lightbox,
      })
      return memo
    }, [])
  }

  getThumbnailContent(image) {
    return (
      <div className={styles.thumbnail}>
        {image.description ? (
          <h3 dangerouslySetInnerHTML={{ __html: image.description }} />
        ) : null}
        <Link to={image.slug} key={image.src} onClick={e => e.preventDefault()}>
          <Img
            fluid={image.fluid}
            placeholderStyle={{
              opacity: 1,
            }}
            fadeIn={false}
            style={{
              backgroundColor: image.colors.light,
            }}
          />
        </Link>
        {image.title ? <h2>{image.title}</h2> : null}
      </div>
    )
  }

  getThumbBoundsFn(index) {
    const items = document.getElementsByClassName('gatsby-image-wrapper')
    if (!items || items.length <= index) {
      return
    }
    const item = items[index]
    if (!item.getBoundingClientRect) {
      return
    }
    const pageYScroll = window.pageYOffset || document.documentElement.scrollTop
    const rect = item.getBoundingClientRect()

    return {
      x: rect.left,
      y: rect.top + pageYScroll,
      w: rect.width,
      h: rect.height,
    }
  }

  scrollToIndex(index) {
    const wh =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    const bounds = this.getThumbBoundsFn(index)

    window.scrollTo(0, bounds.y - (wh - bounds.h) / 2)
  }
}

export const query = graphql`
  query Project($slug: String!) {
    gallery: allGallery(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          slug
          formattedDate
          images {
            src
            width
            height
            slug
            title
            description
            colors {
              light
              dark
            }
            fluid(
              maxWidth: 1024
              widths: [256, 512, 750, 1024, 1600]
              crop: "limit"
            ) {
              src
              srcSet
              aspectRatio
              sizes
            }
            lightbox: fixed(width: 1600, crop: "limit") {
              src
              msrc: src
              w: width
              h: height
            }
          }
          poster {
            src
            width
            height
          }
          project {
            slug
            name
            title
            galleries {
              name
              title
              formattedDate
              slug
              poster {
                fixed(
                  width: 300
                  height: 200
                  quality: 60
                  gravity: "auto"
                  crop: "fill"
                ) {
                  aspectRatio
                  src
                  srcSet
                  width
                  height
                }
                colors {
                  light
                }
              }
            }
          }
        }
      }
    }
  }
`
